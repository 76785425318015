import { roles, service } from './defaults';

export const paths = {
	login: 'auth/login',
	signup: 'auth/signup',
	error404: 'error/404',
	dashboard: '/',
	iotDemo: '/iot/demo',
	masterRoute: {
		list: '/master_route/config',
		form: '/master_route/manage',
	},
	route: {
		list: '/route/config',
		form: '/route/manage',
	},
	user: {
		list: '/user/list',
		form: '/user/manage',
		roles: '/user/roles',
	},
	master: {
		type: '/master/types',
		typeDetails: '/master/types/detail',
	},
	roles: {
		list: '/roles/list',
		form: '/roles/manage',
	},
	driver: {
		list: '/driver/list',
		form: '/driver/manage',
	},
	vehicle: {
		list: '/vehicle/list',
		form: '/vehicle/manage',
	},
	infraPoint: {
		list: '/infta/list',
		form: '/infta/manage',
		userMapping: '/infra/userMapping',
	},
	Merchant: {
		list: '/merchant/list',
		form: '/merchant/manage',
	},
	wasteCategory: {
		list: '/wasteCategory/list',
		details: '/wasteCategory/details',
	},
	customer: {
		list: '/customer/list',
		form: '/customer/manage',
		locations: '/customer/locations',
		history: '/customer/history',
	},
	driverAction: {
		collection: '/driver/action/collection',
		history: '/driver/action/history',
		direction: '/driver/action/direction',
	},
	tenant: {
		list: '/tenant/list',
		form: '/tenant/manage',
	},
	appConfig: {
		list: '/appconfig/list',
		form: '/appconfig/manage',
	},
	deviceManagement: {
		list: '/deviceManagement/list',
		form: '/deviceManagement/manage',
	},
	gpsService: {
		list: '/gpsService/list',
		form: '/gpsService/manage',
	},
	approvals: {
		list: '/approvals/list',
	},
	activityMonitor: {
		peekActivity: '/activity/peek',
		showActivityDetail: '/activity/details/*',
	},
	materialProccessing: {
		list: '/MRF/list',
		details: '/MRF/manage/treatments',
	},
	mrfInward: {
		list: '/MRF/inward/list',
		details: '/MRF/inward/manage',
	},
	mrfOutward: {
		list: '/MRF/outward/list',
		details: '/MRF/outward/manage',
	},
	reports: {
		collectionHistory: '/reports/collectionHistory',
		mrfHistory: '/reports/mrfHistory',
	},
};

export const authentication = {
	auth: {
		id: 'auth',
		text: 'Auth Pages',
		icon: 'Extension',
		service: service.ALL,
		role: roles.ALL,
		subMenu: null,
	},
	login: {
		id: 'login',
		text: 'Login',
		path: paths.login,
		icon: 'Login',
		service: service.ALL,
		role: roles.ALL,
		subMenu: null,
	},
	signUp: {
		id: 'signUp',
		text: 'Sign Up',
		path: paths.signup,
		icon: 'PersonAdd',
		service: service.ALL,
		role: roles.ALL,
		subMenu: null,
	},
};

export const staticPages = {
	page404: {
		id: 'Page404',
		text: '404 Page',
		path: paths.error404,
		icon: 'ReportGmailerrorred',
		service: service.ALL,
		role: roles.ALL,
		subMenu: null,
	},
};

export const mainSection = {
	dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: paths.dashboard,
		icon: 'Dashboard',
		service: service.ALL,
		role: roles.ALL,
		subMenu: null,
	},
	masterRouteConfiguration: {
		id: 'masterRouteConfiguration',
		text: 'Master Route',
		path: paths.masterRoute.list,
		icon: 'BorderOuter',
		service: [service.COLLECTION],
		role: [roles.OPERATION, roles.ADMIN],
		subMenu: null,
	},
	routeConfiguration: {
		id: 'routeConfiguration',
		text: 'Daily Operation',
		path: paths.route.list,
		icon: 'AltRoute',
		service: [service.COLLECTION],
		role: [roles.OPERATION, roles.ADMIN],
		subMenu: null,
	},
	approvals: {
		id: 'approvals',
		text: 'Approvals',
		path: paths.approvals.list,
		icon: 'Approval',
		service: [service.COLLECTION],
		role: [roles.OPERATION, roles.ADMIN],
		subMenu: null,
	},
	peekActivity: {
		id: 'peekActivity',
		text: 'Peek Activity',
		path: paths.activityMonitor.peekActivity,
		icon: 'LocalActivity',
		service: [service.COLLECTION],
		role: [roles.ADMIN, roles.OPERATION],
		subMenu: null,
	},
	materialProccessing: {
		id: 'materialProccessing',
		text: 'MRF-Process',
		path: paths.materialProccessing.list,
		icon: 'Dashboard',
		service: [service.MRF],
		role: [roles.ADMIN, roles.MRF_OPERATION],
		subMenu: null,
	},
	mrfInwards: {
		id: 'mrfInwards',
		text: 'Inwards',
		path: paths.mrfInward.list,
		icon: 'ArrowCircleDown',
		service: [service.MRF],
		role: [roles.ADMIN, roles.MRF_OPERATION],
		subMenu: null,
	},
	mrfOutward: {
		id: 'mrfOutward',
		text: 'Outwards',
		path: paths.mrfOutward.list,
		icon: 'ArrowCircleUp',
		service: [service.MRF],
		role: [roles.ADMIN, roles.MRF_OPERATION],
		subMenu: null,
	},
};

export const userManagement = {
	userManagement: {
		id: 'userManagement',
		text: 'Manage User',
		icon: 'Extension',
		service: service.ALL,
		role: [roles.SUPER_ADMIN],
		subMenu: null,
	},
	users: {
		id: 'users',
		text: 'System Users',
		path: paths.user.list,
		icon: 'SupervisorAccount',
		service: service.ALL,
		role: [roles.SUPER_ADMIN],
		subMenu: null,
	},
	userRole: {
		id: 'userRole',
		text: 'Role Mapping',
		path: paths.user.roles,
		icon: 'RecentActors',
		service: service.ALL,
		role: [roles.SUPER_ADMIN],
		subMenu: null,
	},
};

export const masterDataManagement = {
	masterData: {
		id: 'masterData',
		text: 'Master Data & Config',
		icon: 'Extension',
		service: service.ALL,
		role: [roles.SUPER_ADMIN],
		subMenu: null,
	},
	materItems: {
		id: 'materItems',
		text: 'Mater Data',
		path: paths.master.type,
		icon: 'Book',
		service: service.ALL,
		role: [roles.SUPER_ADMIN],
		subMenu: null,
	},
	roles: {
		id: 'masterRoles',
		text: 'System Roles',
		path: paths.roles.list,
		icon: 'Cases',
		service: service.ALL,
		role: [roles.SUPER_ADMIN],
		subMenu: null,
	},
	gpsServiceConfig: {
		id: 'gpsServiceConfig',
		text: 'GPS Service Provider',
		path: paths.gpsService.list,
		icon: 'AccessTime',
		service: [service.COLLECTION],
		role: [roles.SUPER_ADMIN],
		subMenu: null,
	},
	appConfig: {
		id: 'appConfig',
		text: 'Configurations',
		path: paths.appConfig.list,
		icon: 'AdminPanelSettings',
		service: service.ALL,
		role: [roles.SUPER_ADMIN],
		subMenu: null,
	},
	deviceManagement: {
		id: 'deviceManagement',
		text: 'Device Management',
		path: paths.deviceManagement.list,
		icon: 'DevicesOther',
		service: service.ALL,
		role: [roles.SUPER_ADMIN],
		subMenu: null,
	},
};

export const operations = {
	operations: {
		id: 'operations',
		text: 'Operations',
		icon: 'EmojiTransportation',
		service: service.ALL,
		role: [roles.ADMIN, roles.OPERATION],
		subMenu: null,
	},
	driverManagement: {
		id: 'driverManagement',
		text: 'Drivers',
		path: paths.driver.list,
		icon: 'PersonalInjury',
		service: [service.COLLECTION],
		role: [roles.ADMIN, roles.OPERATION],
		subMenu: null,
	},
	vehicleManagement: {
		id: 'vehicleManagement',
		text: 'Vehicles',
		path: paths.vehicle.list,
		icon: 'DirectionsCar',
		service: service.ALL,
		role: [roles.ADMIN, roles.OPERATION, roles.MRF_OPERATION],
		subMenu: null,
	},
	infraPoints: {
		id: 'infraPoints',
		text: 'Infra Points',
		path: paths.infraPoint.list,
		icon: 'Apartment',
		service: service.ALL,
		role: [roles.ADMIN, roles.OPERATION, roles.MRF_OPERATION],
		subMenu: null,
	},
	userInfrapoint: {
		id: 'userInfrapoint',
		text: 'User - Infra Points',
		path: paths.infraPoint.userMapping,
		icon: 'PersonalInjury',
		service: [service.MRF],
		role: [roles.ADMIN, roles.OPERATION, roles.MRF_OPERATION],
		subMenu: null,
	},
	Merchants: {
		id: 'Merchants',
		text: 'Merchants',
		path: paths.Merchant.list,
		icon: 'SupervisedUserCircle',
		service: [service.MRF],
		role: [roles.ADMIN, roles.MRF_OPERATION],
		subMenu: null,
	},
	categories: {
		id: 'categories',
		text: 'Waste Categories',
		path: paths.wasteCategory.list,
		icon: 'RestoreFromTrash',
		service: service.ALL,
		role: [roles.ADMIN, roles.OPERATION, roles.MRF_OPERATION],
		subMenu: null,
	},
};

export const customerManagement = {
	customerManagement: {
		id: 'customerManagement',
		text: 'Customers',
		icon: 'SupervisedUserCircle',
		service: [service.COLLECTION],
		role: [roles.ADMIN, roles.OPERATION],
		subMenu: null,
	},
	customerOnboarding: {
		id: 'customerOnboarding',
		text: 'Customers',
		path: paths.customer.list,
		icon: 'PersonAddAlt',
		service: [service.COLLECTION],
		role: [roles.ADMIN, roles.OPERATION],
		subMenu: null,
	},
	customerLocationView: {
		id: 'customerLocationView',
		text: 'Customer Locations',
		path: paths.customer.locations,
		icon: 'PersonPinCircle',
		service: [service.COLLECTION],
		role: [roles.ADMIN, roles.OPERATION],
		subMenu: null,
	},
};

export const driverOperations = {
	driverOperations: {
		id: 'driverOperations',
		text: 'Driver Actions',
		icon: 'EmojiTransportation',
		service: [service.COLLECTION],
		role: [roles.ADMIN, roles.DRIVER],
		subMenu: null,
	},
	collectionList: {
		id: 'collectionList',
		text: 'Todays Collection',
		path: paths.driverAction.collection,
		icon: 'DeleteSweep',
		service: [service.COLLECTION],
		role: [roles.ADMIN, roles.DRIVER],
		subMenu: null,
	},
	colectionHistory: {
		id: 'colectionHistory',
		text: 'Colection History',
		path: paths.driverAction.history,
		icon: 'AccessTime',
		service: [service.COLLECTION],
		role: [roles.ADMIN, roles.DRIVER],
		subMenu: null,
	},
};

export const tenantManagement = {
	tenantManagement: {
		id: 'tenantManagement',
		text: 'Tenant Management',
		icon: 'AccessTime',
		service: service.ALL,
		role: [roles.SUPER_ADMIN],
		subMenu: null,
	},
	tenantConfig: {
		id: 'tenantConfig',
		text: 'Manage Tenants',
		path: paths.tenant.list,
		icon: 'AccessTime',
		service: service.ALL,
		role: [roles.SUPER_ADMIN],
		subMenu: null,
	},
};

export const iotDemo = {
	iotDemo: {
		id: 'iotDemo',
		text: 'IOT Demo',
		icon: 'AccessTime',
		service: service.ALL,
		role: [roles.SUPER_ADMIN],
		subMenu: null,
	},
	iotDemoPage: {
		id: 'iotDemoPage',
		text: 'IOT Devices',
		path: paths.iotDemo,
		icon: 'AccessTime',
		service: service.ALL,
		role: [roles.SUPER_ADMIN],
		subMenu: null,
	},
};

export const reports = {
	reports: {
		id: 'reports',
		text: 'Reports',
		icon: 'EmojiTransportation',
		service: [service.COLLECTION],
		role: [roles.ADMIN],
		subMenu: null,
	},
	collectionHistory: {
		id: 'dailyReports',
		text: 'Daily Reports',
		path: paths.reports.collectionHistory,
		icon: 'AccessTime',
		service: [service.COLLECTION],
		role: [roles.ADMIN],
		subMenu: null,
	},
	mrfHistory: {
		id: 'mrfDailyReports',
		text: 'MRF Reports',
		path: paths.reports.mrfHistory,
		icon: 'AccessTime',
		service: [service.MRF, service.RECYCLING],
		role: [roles.ADMIN],
		subMenu: null,
	},
};
