import React, { useContext, useEffect, useLayoutEffect, useRef } from 'react';
import { ThemeProvider } from 'react-jss';
import { ReactNotifications } from 'react-notifications-component';
import { useFullscreen } from 'react-use';
import { Route, Routes } from 'react-router-dom';
import { TourProvider } from '@reactour/tour';
import { LoadScript } from '@react-google-maps/api';
import { Toaster } from 'react-hot-toast';
// import HPlatform from 'react-here-map';
import ThemeContext from '../contexts/themeContext';
import Aside from '../layout/Aside/Aside';
import Wrapper from '../layout/Wrapper/Wrapper';
import Portal from '../layout/Portal/Portal';
import { authentication } from '../menu';
import useDarkMode from '../hooks/useDarkMode';
import COLORS from '../common/data/enumColors';
import { getOS } from '../helpers/helpers';
import steps, { styles } from '../steps';

const App = () => {
	getOS();

	/**
	 * Dark Mode
	 */
	const LIBRARIES = ['places'];
	const GMAP_API_KEY = process.env.REACT_APP_GMAP_API_KEY;
	// const HMAP_APP_KEY = process.env.REACT_APP_HMAP_APP_KEY;
	// const HMAP_API_KEY = process.env.REACT_APP_HMAP_API_KEY;
	const { themeStatus, darkModeStatus } = useDarkMode();
	const theme = {
		theme: themeStatus,
		primary: COLORS.PRIMARY.code,
		secondary: COLORS.SECONDARY.code,
		success: COLORS.SUCCESS.code,
		info: COLORS.INFO.code,
		warning: COLORS.WARNING.code,
		danger: COLORS.DANGER.code,
		dark: COLORS.DARK.code,
		light: COLORS.LIGHT.code,
	};

	useEffect(() => {
		if (darkModeStatus) {
			document.documentElement.setAttribute('theme', 'dark');
			document.documentElement.setAttribute('data-bs-theme', 'dark');
		}
		return () => {
			document.documentElement.removeAttribute('theme');
			document.documentElement.removeAttribute('data-bs-theme');
		};
	}, [darkModeStatus]);

	/**
	 * Full Screen
	 */
	const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
	const ref = useRef(null);
	useFullscreen(ref, fullScreenStatus, { onClose: () => setFullScreenStatus(false) });

	/**
	 * Modern Design
	 */
	useLayoutEffect(() => {
		if (process.env.REACT_APP_MODERN_DESGIN === 'true') {
			document.body.classList.add('modern-design');
		} else {
			document.body.classList.remove('modern-design');
		}
	});

	// eslint-disable-next-line no-unused-vars
	const renderAside = () => {
		const withOutAsidePages = [authentication.login.path, authentication.signUp.path];
		return (
			<Routes>
				{withOutAsidePages.map((path) => (
					<Route key={path} path={path} />
				))}
				<Route path='*' element={<Aside />} />
			</Routes>
		);
	};

	const mainDivStyle = {
		backgroundColor: fullScreenStatus && 'var(--bs-body-bg)',
		zIndex: fullScreenStatus && 1,
		overflow: fullScreenStatus && 'scroll',
	};

	return (
		<ThemeProvider theme={theme}>
			<TourProvider steps={steps} styles={styles} showNavigation={false} showBadge={false}>
				<LoadScript googleMapsApiKey={GMAP_API_KEY} libraries={LIBRARIES}>
					<div ref={ref} className='app' style={mainDivStyle}>
						{/* Enable this line to add in the side bar */}
						{renderAside()}
						<Wrapper />
					</div>
					<Toaster />
					<Portal id='portal-notification'>
						<ReactNotifications />
					</Portal>
				</LoadScript>
			</TourProvider>
		</ThemeProvider>
	);
};

export default App;
