import React, { lazy } from 'react';
import { paths } from '../menu';
import Login from '../pages/common/auth/Login';

const AUTH = {
	PAGE_404: lazy(() => import('../pages/common/auth/Page404')),
};

const LANDING = {
	DASHBOARD: lazy(() => import('../pages/dashboard/DashboardPage')),
};

const MASTER = {
	TYPE_LIST: lazy(() => import('../pages/master/MasterDataPage')),
	TYPE_DETAILS: lazy(() => import('../pages/master/MasterDataDetails')),
};

const WASTE_CATTEGORY = {
	LIST: lazy(() => import('../pages/wasteCategories/WasteCategoryPage')),
	DETAILS: lazy(() => import('../pages/wasteCategories/WasteCategoryDetails')),
};

const ROLES = {
	LIST: lazy(() => import('../pages/roles/RolesList')),
	FORM: lazy(() => import('../pages/roles/RolesForm')),
};

const CUSTOMERS = {
	LIST: lazy(() => import('../pages/customer/CustomerOnboarding')),
	FORM: lazy(() => import('../pages/customer/CustomerOnboardingForm')),
	LOCATIONS: lazy(() => import('../pages/customer/ViewCustomerLocation')),
	HISTORY: lazy(() => import('../pages/customer/CustomerHistoryView')),
};

const DRIVER = {
	LIST: lazy(() => import('../pages/driver/DriverOnboarding')),
	FORM: lazy(() => import('../pages/driver/DriverOnboardingForm')),
};

const VEHICLE = {
	LIST: lazy(() => import('../pages/vehicle/VehicleOnboarding')),
	FORM: lazy(() => import('../pages/vehicle/VehicleOnboardingForm')),
};

const INFRA_POINT = {
	LIST: lazy(() => import('../pages/infraPoints/InfraPointList')),
	FORM: lazy(() => import('../pages/infraPoints/InfraPointForm')),
	USER_MAPPING: lazy(() => import('../pages/infraPoints/UserInfra')),
};

const MERCHANT = {
	LIST: lazy(() => import('../pages/merchant/MerchantOnboarding')),
	FORM: lazy(() => import('../pages/merchant/MerchantOnboardingForm')),
};

const MASTER_COLLECTION_ROUTE = {
	LIST: lazy(() => import('../pages/masterRoute/MasterRouteConfigList')),
	FORM: lazy(() => import('../pages/masterRoute/MasterRouteConfigForm')),
};

const COLLECTION_ROUTE = {
	LIST: lazy(() => import('../pages/dailyCollection/RouteConfigList')),
	FORM: lazy(() => import('../pages/dailyCollection/RouteConfigForm')),
};

const USER = {
	LIST: lazy(() => import('../pages/user/UserList')),
	FORM: lazy(() => import('../pages/user/UserForm')),
	ROLES_LIST: lazy(() => import('../pages/user/UserRole')),
};

const DRIVER_ACTIONS = {
	COLECTION_ROUTE: lazy(() => import('../pages/driverActions/CollectionList')),
	DIRECTION_TO_POINT: lazy(() => import('../pages/driverActions/DirectionToPoint')),
};

const TENANT = {
	LIST: lazy(() => import('../pages/tenant/TenantManagement')),
	FORM: lazy(() => import('../pages/tenant/TenantManagementForm')),
};

const APP_CONFIG = {
	LIST: lazy(() => import('../pages/appConfig/AppConfigPage')),
	FORM: lazy(() => import('../pages/appConfig/AppConfigForm')),
};

const DEVICE_MANAGEMENT = {
	LIST: lazy(() => import('../pages/deviceManagement/DeviceManagementPage')),
	FORM: lazy(() => import('../pages/deviceManagement/DeviceManagementForm')),
};

const GPS_SERVICE = {
	LIST: lazy(() => import('../pages/gpsService/GpsServiceList')),
	FORM: lazy(() => import('../pages/gpsService/GpsServiceForm')),
};

const APPROVALS = {
	LIST: lazy(() => import('../pages/approvals/ApprovalList')),
	FORM: lazy(() => import('../pages/approvals/ApprovalForm')),
};

const ACTIVITY = {
	LIST: lazy(() => import('../pages/peekActivity/ActivityList')),
	DETAILS: lazy(() => import('../pages/peekActivity/ActivityDetails')),
};

const REPORTS = {
	COLLECTION_REPORT: lazy(() => import('../pages/reports/CollectionHistory')),
	MRF_REPORT: lazy(() => import('../pages/reports/MrfHistory')),
};

const DEMO = {
	IOT: lazy(() => import('../pages/IOT/IOTDemoPage')),
};
const MRF = {
	LIST: lazy(() => import('../pages/materialProcesing/MrfDashboard')),
	DETAILS: lazy(() => import('../pages/materialProcesing/MrfProcessStatus')),
};

const MRF_INWARD = {
	LIST: lazy(() => import('../pages/mrf/Inward')),
	DETAILS: lazy(() => import('../pages/mrf/InwardDetail')),
};

const MRF_OUTWARD = {
	LIST: lazy(() => import('../pages/mrf/Outward')),
	DETAILS: lazy(() => import('../pages/mrf/OutwardDetail')),
};

const presentation = [
	// #region DASHBOARD
	{
		path: paths.dashboard,
		element: <LANDING.DASHBOARD />,
		exact: true,
	},
	// #endregion

	// #region MASTER_COLLECTION_ROUTE
	{
		path: paths.masterRoute.list,
		element: <MASTER_COLLECTION_ROUTE.LIST />,
		exact: true,
	},
	{
		path: paths.masterRoute.form,
		element: <MASTER_COLLECTION_ROUTE.FORM />,
		exact: true,
	},

	// #region COLLECTION_ROUTE
	{
		path: paths.route.list,
		element: <COLLECTION_ROUTE.LIST />,
		exact: true,
	},
	{
		path: paths.route.form,
		element: <COLLECTION_ROUTE.FORM />,
		exact: true,
	},
	// #endregion

	// #region USER
	{
		path: paths.user.list,
		element: <USER.LIST />,
		exact: true,
	},
	{
		path: paths.user.form,
		element: <USER.FORM />,
		exact: true,
	},
	{
		path: paths.user.roles,
		element: <USER.ROLES_LIST />,
		exact: true,
	},
	// #endregion

	// #region MASTER
	{
		path: paths.master.type,
		element: <MASTER.TYPE_LIST />,
		exact: true,
	},
	{
		path: paths.master.typeDetails,
		element: <MASTER.TYPE_DETAILS />,
		exact: true,
	},
	// #endregion

	// #region ROLES
	{
		path: paths.roles.list,
		element: <ROLES.LIST />,
		exact: true,
	},
	{
		path: paths.roles.form,
		element: <ROLES.FORM />,
		exact: true,
	},
	// #endregion

	// #region CUSTOMERS
	{
		path: paths.customer.list,
		element: <CUSTOMERS.LIST />,
		exact: true,
	},
	{
		path: paths.customer.form,
		element: <CUSTOMERS.FORM />,
		exact: true,
	},
	{
		path: paths.customer.locations,
		element: <CUSTOMERS.LOCATIONS />,
		exact: true,
	},
	{
		path: paths.customer.history,
		element: <CUSTOMERS.HISTORY />,
		exact: true,
	},
	// #endregion

	// #region DRIVER
	{
		path: paths.driver.list,
		element: <DRIVER.LIST />,
		exact: true,
	},
	{
		path: paths.driver.form,
		element: <DRIVER.FORM />,
		exact: true,
	},
	// #endregion

	// #region VEHICLE
	{
		path: paths.vehicle.list,
		element: <VEHICLE.LIST />,
		exact: true,
	},
	{
		path: paths.vehicle.form,
		element: <VEHICLE.FORM />,
		exact: true,
	},
	// #endregion

	// #region INFRA_POINT
	{
		path: paths.infraPoint.list,
		element: <INFRA_POINT.LIST />,
		exact: true,
	},
	{
		path: paths.infraPoint.form,
		element: <INFRA_POINT.FORM />,
		exact: true,
	},
	{
		path: paths.infraPoint.userMapping,
		element: <INFRA_POINT.USER_MAPPING />,
		exact: true,
	},
	// #endregion

	// #region MERCHANT
	{
		path: paths.Merchant.list,
		element: <MERCHANT.LIST />,
		exact: true,
	},
	{
		path: paths.Merchant.form,
		element: <MERCHANT.FORM />,
		exact: true,
	},
	// #endregion

	// #region WASTE_CATTEGORY
	{
		path: paths.wasteCategory.list,
		element: <WASTE_CATTEGORY.LIST />,
		exact: true,
	},
	{
		path: paths.wasteCategory.details,
		element: <WASTE_CATTEGORY.DETAILS />,
		exact: true,
	},
	// #endregion

	// #region DRIVER_ACTIONS
	{
		path: paths.driverAction.collection,
		element: <DRIVER_ACTIONS.COLECTION_ROUTE />,
		exact: true,
	},
	{
		path: paths.driverAction.direction,
		element: <DRIVER_ACTIONS.DIRECTION_TO_POINT />,
		exact: true,
	},
	// #endregion

	// #region TENANT
	{
		path: paths.tenant.list,
		element: <TENANT.LIST />,
		exact: true,
	},
	{
		path: paths.tenant.form,
		element: <TENANT.FORM />,
		exact: true,
	},
	// #endregion

	// #region APP_CONFIG
	{
		path: paths.appConfig.list,
		element: <APP_CONFIG.LIST />,
		exact: true,
	},
	{
		path: paths.appConfig.form,
		element: <APP_CONFIG.FORM />,
		exact: true,
	},
	// #endregion

	// #region DEVICE_MANAGEMENT
	{
		path: paths.deviceManagement.list,
		element: <DEVICE_MANAGEMENT.LIST />,
		exact: true,
	},
	{
		path: paths.deviceManagement.form,
		element: <DEVICE_MANAGEMENT.FORM />,
		exact: true,
	},
	// #endregion

	// #region GPS_SERVICE
	{
		path: paths.gpsService.list,
		element: <GPS_SERVICE.LIST />,
		exact: true,
	},
	{
		path: paths.gpsService.form,
		element: <GPS_SERVICE.FORM />,
		exact: true,
	},
	// #endregion

	// #region AUTH
	{
		path: paths.error404,
		element: <AUTH.PAGE_404 />,
		exact: true,
	},
	{
		path: paths.login,
		element: <Login />,
		exact: true,
	},
	{
		path: paths.signup,
		element: <Login isSignUp />,
		exact: true,
	},
	// #endregion

	// #region APPROVALS
	{
		path: paths.approvals.list,
		element: <APPROVALS.LIST />,
		exact: true,
	},
	// #endregion

	// #region ACTIVITY_MONITOR
	{
		path: paths.activityMonitor.peekActivity,
		element: <ACTIVITY.LIST />,
		exact: true,
	},
	{
		path: paths.activityMonitor.showActivityDetail,
		element: <ACTIVITY.DETAILS />,
		exact: true,
	},
	// #endregion

	// #region REPORTS
	{
		path: paths.reports.collectionHistory,
		element: <REPORTS.COLLECTION_REPORT />,
		exact: true,
	},
	{
		path: paths.reports.mrfHistory,
		element: <REPORTS.MRF_REPORT />,
		exact: true,
	},
	// #endregion

	// #region DEMO
	{
		path: paths.iotDemo,
		element: <DEMO.IOT />,
		exact: true,
	},
	// #endregion

	// #region MRF
	{
		path: paths.materialProccessing.list,
		element: <MRF.LIST />,
		exact: true,
	},
	{
		path: paths.materialProccessing.details,
		element: <MRF.DETAILS />,
		exact: true,
	},

	{
		path: paths.mrfInward.list,
		element: <MRF_INWARD.LIST />,
		exact: true,
	},
	{
		path: paths.mrfInward.details,
		element: <MRF_INWARD.DETAILS />,
		exact: true,
	},
	{
		path: paths.mrfOutward.list,
		element: <MRF_OUTWARD.LIST />,
		exact: true,
	},
	{
		path: paths.mrfOutward.details,
		element: <MRF_OUTWARD.DETAILS />,
		exact: true,
	},
	// #endregion
];

const contents = [...presentation];

export default contents;
